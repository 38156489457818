<template>
	<!-- <div class="banner">
		<img alt="Banner" src="/banner-motul-web.jpg"/>
	</div> -->
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">

			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="grid">
		<div class="col-12 md:col-6 lg:col-4">
			<UserAktif/>
        </div>
		<div class="col-12 md:col-6 lg:col-4">
			<OutletMitra/>
        </div>
		<div class="col-12 md:col-6 lg:col-4">
			<OutletSpreading/>
        </div>
	</div> -->
</template>

<script>
import { mapGetters } from "vuex";
// import UserAktif from '../components/dashboard/UserAktif.vue';
// import OutletMitra from '../components/dashboard/OutletMitra.vue';
// import OutletSpreading from '../components/dashboard/OutletSpreading.vue';
import Error from '../components/Error.vue';

export default {
	components: {
		// 'UserAktif': UserAktif,
		// 'OutletMitra': OutletMitra,
		// 'OutletSpreading': OutletSpreading,
		'Error': Error,
	},
	computed:{
        ...mapGetters(['errors']),
    },
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,
		}
	},
}
</script>
<style>
	.banner {
		width: 100%;
		/* height: 200px; */
		background-color: #f0f0f0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.banner img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}
</style>